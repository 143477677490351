'use client';

import { ChevronRight, type LucideIcon } from 'lucide-react';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import useDashboard from '~/app/hooks/useDashboard';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '~/components/ui/collapsible';
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from '~/components/ui/sidebar';
import { cn } from '~/lib/utils';

export function NavMain({
  items,
}: {
  items: {
    title: string;
    url: string;
    icon: LucideIcon;
    isActive?: boolean;
    items?: {
      title: string;
      url: string;
    }[];
  }[];
}) {
  const router = useRouter();
  const { openSidebar } = useDashboard();
  return (
    <SidebarGroup>
      <SidebarGroupLabel className="group-data-[collapsible=icon]:hidden flex p-2 text-content-secondary self-stretch items-center font-semibold s1">
        Product
      </SidebarGroupLabel>
      <SidebarMenu>
        {items.map((item) => {
          const childrenEle = (
            <>
              <item.icon size={16} />
              <span className="b3 font-normal text-content-primary">
                {item.title}
              </span>
            </>
          );
          return (
            <Collapsible
              key={item.title}
              asChild
              defaultOpen={item.isActive}
              className="group/collapsible"
            >
              <SidebarMenuItem>
                <CollapsibleTrigger asChild>
                  <SidebarMenuButton
                    tooltip={item.title}
                    className="hover:bg-surface-tertiary hover:text-surface-tertiary-foreground"
                  >
                    {item.items?.length ? (
                      <div
                        className="flex items-center gap-2"
                        onClick={(e) => {
                          const isIconState = e.currentTarget.closest(
                            '[data-collapsible="icon"]',
                          );
                          if (isIconState) router.push(item.url as string);
                        }}
                      >
                        {childrenEle}
                      </div>
                    ) : (
                      <Link
                        className={cn(
                          'flex items-center gap-2',
                          openSidebar && 'w-full',
                        )}
                        href={item.url}
                        scroll
                      >
                        {childrenEle}
                      </Link>
                    )}

                    {item.items?.length && (
                      <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                    )}
                  </SidebarMenuButton>
                </CollapsibleTrigger>
                {item.items?.length ? (
                  <CollapsibleContent>
                    <SidebarMenuSub>
                      {item.items?.map((subItem) => (
                        <SidebarMenuSubItem key={subItem.title}>
                          <SidebarMenuSubButton asChild>
                            <Link href={subItem.url} scroll>
                              <span className="b3 font-normal text-content-primary">
                                {subItem.title}
                              </span>
                            </Link>
                          </SidebarMenuSubButton>
                        </SidebarMenuSubItem>
                      ))}
                    </SidebarMenuSub>
                  </CollapsibleContent>
                ) : null}
              </SidebarMenuItem>
            </Collapsible>
          );
        })}
      </SidebarMenu>
    </SidebarGroup>
  );
}
