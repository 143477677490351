'use client';

import { Gem } from 'lucide-react';
import Link from 'next/link';
import { useTeamTier } from '~/app/hooks/useTeamTier/useTeamTier';
import { Button } from '~/components/ui/button';
import {
  PLANS_MAX_MONTHLY_EVENTS,
  PLANS_MAX_MONTHLY_USERS,
} from '~/constants/plans';

import UsageProgress, {
  CategoryData,
} from '../../team/components/UsageProcess';

export function NavBilling() {
  const { activeTeamTier, currentEventsCount, currentUsersCount } =
    useTeamTier();

  const maxEvents = activeTeamTier?.type
    ? PLANS_MAX_MONTHLY_EVENTS[
        activeTeamTier?.type as keyof typeof PLANS_MAX_MONTHLY_EVENTS
      ] || 0
    : 0;

  const maxUsers = activeTeamTier?.type
    ? PLANS_MAX_MONTHLY_USERS[
        activeTeamTier?.type as keyof typeof PLANS_MAX_MONTHLY_USERS
      ] || 0
    : 0;

  const categories: CategoryData[] = [
    {
      items: [
        {
          subLabel: 'Events',
          current: currentEventsCount || 0,
          max: maxEvents,
          useUnlimitedCheck: activeTeamTier?.type === 'enterprise',
        },
        {
          subLabel: 'Users',
          current: currentUsersCount || 0,
          max: maxUsers,
          useUnlimitedCheck: activeTeamTier?.type === 'enterprise',
        },
      ],
    },
  ];

  return (
    <>
      {activeTeamTier?.team && (
        <div className="px-2">
          <UsageProgress
            variant="stacked"
            gap="small"
            categories={categories}
          />
          <Link href={`/teams/${activeTeamTier.team?.id}/upgrade`}>
            <Button
              type="button"
              className="b3 font-semibold w-full bg-gradient-to-r from-[#A1FF40] to-[#FFC935] mt-4"
            >
              <Gem /> Upgrade plan
            </Button>
          </Link>
        </div>
      )}
    </>
  );
}
