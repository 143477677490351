import { PLANS_NAMES, PlanType } from '@formo/shared';

type FeatureValues = {
  [key in PlanType]?: string | boolean;
};

interface FeatureItem {
  name: string;
  isComingSoon: boolean;
  tooltip?: string;
  values: FeatureValues;
}

interface FeatureCategory {
  category: string;
  icon: string;
  items: FeatureItem[];
}

export const PLANS_CARD = PLANS_NAMES.map((type, index) => ({
  type,
  priority: index,
  price: getPlanPrice(type),
  title: getPlanTitle(type),
  features: getPlanFeatures(type),
}));

export const PLANS_MAX_FORM = {
  free: 3,
  growth: 10,
  enterprise: Number.MAX_SAFE_INTEGER,
};

export const PLANS_MAX_MONTHLY_EVENTS = {
  free: 10000,
  growth: 500000,
  enterprise: Number.MAX_SAFE_INTEGER,
};

export const PLANS_MAX_MONTHLY_USERS = {
  free: 100,
  growth: 5000,
  enterprise: Number.MAX_SAFE_INTEGER,
};

export const PLANS_MAX_SEATS = {
  free: 1,
  growth: 5,
  enterprise: 10,
};

export const PLANS_MAX_RESPONSES = {
  free: 500,
  growth: 100000,
  enterprise: Number.MAX_SAFE_INTEGER,
};

export const FEATURES: FeatureCategory[] = [
  {
    category: 'Dapp Analytics',
    icon: '📊',
    items: [
      {
        name: 'Web Analytics',
        isComingSoon: false,
        values: { free: true, growth: true, enterprise: true },
      },
      {
        name: 'Blockchains',
        isComingSoon: false,
        values: { free: '1', growth: '3', enterprise: 'Unlimited' },
      },
      {
        name: 'Monthly Events',
        tooltip:
          'An event is a single user action on your dapp or an onchain transaction on your contracts. Monthly events are events that occured in the last 30 days.',
        isComingSoon: false,
        values: { free: '10,000', growth: '500,000', enterprise: 'Unlimited' },
      },
      {
        name: 'Onchain Attribution',
        isComingSoon: false,
        values: { free: true, growth: true, enterprise: true },
      },
      {
        name: 'Real-time Activity Feed',
        isComingSoon: false,
        values: { free: '24 hours', growth: true, enterprise: true },
      },
      {
        name: 'Funnels',
        isComingSoon: true,
        values: { free: false, growth: true, enterprise: true },
      },
      {
        name: 'Event Filters',
        isComingSoon: true,
        values: { free: false, growth: true, enterprise: true },
      },
      {
        name: 'Real-time Notifications & Webhooks',
        isComingSoon: true,
        values: { free: false, growth: true, enterprise: true },
      },
      {
        name: 'Data Retention',
        isComingSoon: false,
        values: { free: '90 days', growth: '2 years', enterprise: 'Unlimited' },
      },
      {
        name: 'Platform API Access',
        isComingSoon: true,
        values: { free: false, growth: false, enterprise: true },
      },
    ],
  },
  {
    category: 'Wallet Intelligence',
    icon: '🎯',
    items: [
      {
        name: 'Monthly Tracked Users (MTU)',
        tooltip:
          'Monthly Tracked Users (MTU) are the number of anonymous and identified users that you track with Formo with at least one active session. We only count users once per month, even if they perform multiple actions across your site or app.',
        isComingSoon: false,
        values: { free: '100', growth: '5,000', enterprise: 'Unlimited' },
      },
      {
        name: 'Wallet Profiles',
        isComingSoon: false,
        values: { free: true, growth: true, enterprise: true },
      },
      {
        name: 'Wallet Activity Feed',
        isComingSoon: false,
        values: { free: '24 hours', growth: true, enterprise: true },
      },
      {
        name: 'Dapps and Tokens',
        isComingSoon: false,
        values: { free: false, growth: '1,000', enterprise: true },
      },
      {
        name: 'Wallet Reputation Score',
        isComingSoon: true,
        values: { free: false, growth: true, enterprise: true },
      },
      {
        name: 'Wallet Messaging',
        isComingSoon: true,
        values: { free: false, growth: true, enterprise: true },
      },
      {
        name: 'Cohort analysis',
        isComingSoon: true,
        values: { free: false, growth: true, enterprise: true },
      },
      {
        name: 'Audiences Segmentation',
        isComingSoon: true,
        values: { free: false, growth: true, enterprise: true },
      },
      {
        name: 'Import wallets',
        isComingSoon: true,
        values: { free: false, growth: '5,000', enterprise: 'Unlimited' },
      },
    ],
  },
  {
    category: 'Token Gated Forms',
    icon: '️️⚙️',
    items: [
      {
        name: 'Number of Active Forms',
        isComingSoon: false,
        values: { free: '3', growth: '10', enterprise: 'Unlimited' },
      },
      {
        name: 'Total Monthly Responses',
        isComingSoon: false,
        values: { free: '500', growth: '100,000', enterprise: 'Unlimited' },
      },
      {
        name: 'Token Gating',
        isComingSoon: false,
        values: { free: true, growth: true, enterprise: true },
      },
      {
        name: 'Social Verification',
        isComingSoon: false,
        values: { free: false, growth: true, enterprise: true },
      },
      {
        name: 'Brand Customization',
        isComingSoon: false,
        values: { free: true, growth: true, enterprise: true },
      },
      {
        name: 'Custom Subdomain',
        isComingSoon: false,
        values: { free: false, growth: true, enterprise: true },
      },
      {
        name: 'Third-party Integrations',
        isComingSoon: false,
        values: { free: false, growth: true, enterprise: true },
      },
    ],
  },
  {
    category: 'Workspace',
    icon: '🧑🏻‍💻',
    items: [
      {
        name: 'Workspace Seats',
        isComingSoon: false,
        values: { free: '1', growth: '5', enterprise: '10' },
      },
      {
        name: 'SSO/SAML',
        isComingSoon: true,
        values: { free: false, growth: false, enterprise: true },
      },
    ],
  },
];

export function getPlanPrice(type: string) {
  const prices: Record<string, { monthly: number; annual: number }> = {
    free: { monthly: 0, annual: 0 },
    growth: { monthly: 199, annual: 199 * 12 * 0.8 },
    enterprise: { monthly: 749, annual: 749 * 12 * 0.8 },
  };
  return prices[type] ?? { monthly: 0, annual: 0 };
}

function getPlanTitle(type: string) {
  const titles: Record<string, string> = {
    free: 'Dapp Analytics & Forms:',
    growth: 'Everything in Free, plus:',
    enterprise: 'Everything in Growth, plus:',
  };
  return titles[type] ?? 'Custom Plan';
}

function getPlanFeatures(type: string) {
  const features: Record<string, string[]> = {
    free: [
      '1 chain',
      '10,000 monthly events',
      'Visitor analytics',
      'Wallet profiles',
      'Token-gated forms',
    ],
    growth: [
      '3 chains',
      '500,000 monthly events',
      '5,000 MTU',
      'Real-time activity feed',
      'Dapps and tokens data',
    ],
    enterprise: [
      'Unlimited chains',
      'Unlimited monthly events',
      'Real-time notifications & webhooks',
      'Platform API access',
      'SSO/SAML',
    ],
  };
  return features[type] ?? [];
}
