'use client';

import { ChevronRight, type LucideIcon } from 'lucide-react';
import { Url } from 'next/dist/shared/lib/router/router';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '~/components/ui/collapsible';
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from '~/components/ui/sidebar';

export function NavSettings({
  settings,
}: {
  settings: {
    title: string;
    url?: string;
    icon: LucideIcon;
    isActive?: boolean;
    items: {
      title: string;
      url?: string;
      onClick?: () => void;
    }[];
  }[];
}) {
  const router = useRouter();
  return (
    <SidebarGroup>
      <SidebarGroupLabel className="group-data-[collapsible=icon]:hidden flex p-2 text-content-secondary self-stretch items-center font-semibold s1">
        Workspace
      </SidebarGroupLabel>
      <SidebarMenu>
        {settings.map((item) => (
          <Collapsible
            key={item.title}
            asChild
            defaultOpen={item.isActive}
            className="group/collapsible"
          >
            <SidebarMenuItem>
              <CollapsibleTrigger asChild>
                <SidebarMenuButton
                  tooltip={item.title}
                  className="hover:bg-surface-tertiary hover:text-surface-tertiary-foreground"
                >
                  <div
                    className="flex items-center gap-2"
                    onClick={(e) => {
                      const isIconState = e.currentTarget.closest(
                        '[data-collapsible="icon"]',
                      );
                      if (item.items?.length && isIconState)
                        router.push(item.url as string);
                    }}
                  >
                    <item.icon size={16} />
                    <span className="b3 font-normal text-content-primary">
                      {item.title}
                    </span>
                  </div>
                  {item.items?.length && (
                    <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                  )}
                </SidebarMenuButton>
              </CollapsibleTrigger>
              {item.items?.length ? (
                <CollapsibleContent>
                  <SidebarMenuSub>
                    {item.items?.map((subItem) => (
                      <SidebarMenuSubItem key={subItem.title}>
                        <SidebarMenuSubButton asChild>
                          <Link
                            href={subItem.url as Url}
                            onClick={subItem.onClick ?? undefined}
                          >
                            <span className="b3 font-normal text-content-primary">
                              {subItem.title}
                            </span>
                          </Link>
                        </SidebarMenuSubButton>
                      </SidebarMenuSubItem>
                    ))}
                  </SidebarMenuSub>
                </CollapsibleContent>
              ) : null}
            </SidebarMenuItem>
          </Collapsible>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
}
