export const MIN_WIDTH_SCREEN = 1000;

export const THREE_SECONDS = 3_000;
export const FIVE_SECONDS = 5_000;
export const TEN_SECONDS = 10_000;

export const THIRTY_DAYS = 30 * 24 * 60 * 60 * 1000;

export const SIDEBAR_COOKIE_NAME = 'sidebar:state';
export const SIDEBAR_COOKIE_MAX_AGE = 60 * 60 * 24 * 7;
export const SIDEBAR_WIDTH = '16rem';
export const SIDEBAR_WIDTH_MOBILE = '18rem';
export const SIDEBAR_WIDTH_ICON = '3rem';
export const SIDEBAR_KEYBOARD_SHORTCUT = 'b';
