import { Progress } from '@formo/ui';
import React from 'react';
import { cn } from '~/lib/utils';

export interface ProgressItem {
  subLabel: string;
  current: number;
  max: number;
  useUnlimitedCheck?: boolean;
}

export interface CategoryData {
  category?: string;
  items: ProgressItem[];
}

export interface CategoryProgressProps {
  gap?: 'small' | 'medium';
  categories: CategoryData[];
  variant?: 'inline' | 'stacked';
}

const getRatio = (item: ProgressItem) => {
  return item.useUnlimitedCheck
    ? item.current > 0
      ? 1
      : 0
    : item.max
      ? Math.min(item.current / item.max, 1)
      : 0;
};

const getIndicatorClass = (item: ProgressItem, ratio: number) => {
  if (!item.useUnlimitedCheck) {
    return ratio === 1
      ? 'bg-orange-500'
      : ratio > 0.75
        ? 'bg-yellow-500'
        : 'bg-cyan-200';
  }
  return ratio > 0 ? 'bg-cyan-500' : 'bg-cyan-50';
};

const formatNumber = (num: number) =>
  num >= 1000
    ? (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
    : num.toLocaleString();

const getDisplayValue = (item: ProgressItem, variant?: 'stacked') => {
  const formatter = variant
    ? formatNumber
    : (num: number) => num.toLocaleString();

  if (!item.useUnlimitedCheck)
    return `${formatter(item?.current)} of ${formatter(item?.max)}`;

  return `${formatter(item?.current)}`;
};

const UsageProgress: React.FC<CategoryProgressProps> = ({
  categories,
  gap = 'medium',
  variant = 'inline',
}) => {
  return (
    <>
      {categories.map((cat, catIndex) => (
        <div
          key={catIndex}
          className={cn(
            'grid',
            variant === 'stacked' ? 'grid-cols-1' : 'grid-cols-[200px_1fr]',
            gap === 'small' ? 'py-1' : 'py-4',
          )}
        >
          {cat.category && (
            <div className="b3 text-content-secondary">{cat.category}</div>
          )}

          <div
            className={cn('flex flex-col', gap === 'small' ? 'gap-1' : 'gap-2')}
          >
            {cat.items.map((item, idx) => {
              const ratio = getRatio(item);
              const indicatorClass = getIndicatorClass(item, ratio);
              const valueText =
                variant === 'stacked'
                  ? getDisplayValue(item, variant)
                  : getDisplayValue(item);

              return (
                <div key={idx}>
                  <div className="b3 flex justify-between">
                    {item.subLabel}
                    {variant === 'stacked' && (
                      <span className="flex-shrink-0 b3">{valueText}</span>
                    )}
                  </div>

                  <div className="flex gap-4 items-center">
                    <div className="relative overflow-hidden w-full max-w-[346px]">
                      <Progress
                        value={ratio * 100}
                        classNames={{
                          indicator: indicatorClass,
                          root: 'bg-cyan-50 w-full',
                        }}
                      />

                      {item.useUnlimitedCheck && ratio > 0 && (
                        <div className="absolute top-0 right-[2px] flex">
                          {[...Array(4)].map((_, i) => (
                            <div
                              key={i}
                              className="w-2 h-2"
                              style={{
                                clipPath:
                                  'polygon(0 0, 50% 0, 100% 100%, 50% 100%)',
                              }}
                            >
                              <div className="w-full h-full bg-cyan-50" />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    {variant === 'inline' && (
                      <span className="flex-shrink-0 b3">{valueText}</span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </>
  );
};

export default UsageProgress;
